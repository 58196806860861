import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Container, Grid } from "../../components/grid/grid"
import ImageDrivers from "../../images/conductores.gif"
import ImageDriversx2 from "../../images/conductores@2x.gif"


const ThanksPage = () => (
  <Layout>
    <SEO title="El formulario se ha enviado con éxito" />

    <Container>
      <Grid>
        <div class="l-start-1 l-end-13 m-start-1 m-end-7 center">
          <img src={ImageDrivers} srcSet={ImageDrivers + " 1x, " + ImageDriversx2 + " 2x"} alt="conductores parquick"/>
          <h1>¡Gracias!</h1>
          <p>Nos vemos pronto.</p>
        </div>
      </Grid>
    </Container>
  </Layout>
)

export default ThanksPage
